import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import CookieNotice from "../components/component.cookie-notice";
import ComponentParser from "../components/component.parser";

const BoatTemplate = ({ data }) => {
    const { title,  blocks, featuredImage, seo, ACFFeaturedVideoBlockFields: video, language, translations, slug } = data.wordpress.page;
    const bannerImage = featuredImage ? featuredImage.imageFile : false;
    
    return (
        <Layout language={language} translations={translations}>
            <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />
            <HeroBanner title={title} image={bannerImage} video={video} />
            
            <div className="c-boat-racing">
                {blocks &&
                    <ComponentParser blocks={blocks} />
                }
            </div>
            
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                language {
                    code
                    locale
                }
                translations {
                    # id
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ACFFeaturedVideoBlockFields {
                    videoMp4 {
                        mediaItemUrl
                    }
                    videoWebm {
                        mediaItemUrl
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...BoatBlocks
            }
        }
    }
`;

export default BoatTemplate;
